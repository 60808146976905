import { render, staticRenderFns } from "./_slug.vue?vue&type=template&id=6e55cb88&scoped=true&"
import script from "./_slug.vue?vue&type=script&lang=js&"
export * from "./_slug.vue?vue&type=script&lang=js&"
import style0 from "./_slug.vue?vue&type=style&index=0&id=6e55cb88&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e55cb88",
  null
  
)

export default component.exports