<template>
  <div class="ml-quiz-layout">
    <template v-if="!isMobile">
      <div class="quiz-header position-relative">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-2">
              <nuxt-link to="/">
                <img alt="logo" src="~/mlearn/icons/menu/logo.svg" class="logo">
              </nuxt-link>
            </div>
            <div class="col-lg-10">
              <div class="quiz-name">{{ detail.name }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="quiz-sub-header" :class="{'quiz-sticky': quizSticky}">
        <div class="container">
          <div class="d-flex align-items-center justify-content-end">
            <span class="quiz-time">
              <img src="~/mlearn/icons/quiz/time.svg"/>
              {{$helper.convertTimeLeft(timeLeft)}}
            </span>
            <button class="quiz-pause" v-if="started && !showNotifyPauseStart" @click.prevent="checkBeforePause()">Tạm dừng</button>
            <button class="quiz-continue" v-else @click.prevent="handerContinute()">Tiếp tục</button>
            <button class="quiz-submit" @click.prevent="checkBeforeSubmit()">Nộp bài</button>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="container quiz-header-mobile">
        <div class="quiz-name font-weight-bold">
          {{ detail.name }}
        </div>
        <div class="row pt-3">
          <div class="col-6">
            <div class="quiz-number">
              Câu: <span class="font-weight-bold">{{ indexShow + 1 }}</span>/{{ detail.questions.length }}
            </div>
          </div>
          <div class="col-6">
            <span class="quiz-time d-flex align-items-center float-right">
              <img src="~/mlearn/icons/quiz/time.svg" class="mr-2"/>
              {{$helper.convertTimeLeft(timeLeft)}}
            </span>
          </div>
        </div>
      </div>
    </template>
    <div class="container container-mobile quiz-content">
      <div class="row">
        <div class="col-sm-9">
          <div class="quiz-list-question">
            <Question v-for="(item,index) in dataShow" :key="item.id" :item="item" :index="getIndexShow(index)" :exam-id="detail.id" :started="started" type-question="take" @reasonQuestion="reasonQuestion"/>
          </div>
        </div>
        <div class="col-sm-3 quiz-list-short d-none d-lg-block" ref="quiz-list-short">
          <div :class="{'quiz-sticky': listSticky}" :style="{width: listSticky ? ($refs['quiz-list-short'].clientWidth - 20)+'px' : ''}">
            <div class="quiz-list-short-form">
              <div class="title text-uppercase">Câu hỏi</div>
              <div class="quiz-list-question-short scrollbar">
                <div class="custom-row">
                  <div class="custom-col-2 quiz-question-answer text-center" v-for="(item,index) in detail.questions" :key="item.id" @click="scrollToQuestion(index)" :class="{'primary' : item.is_answered}">
                    <span class="d-flex align-items-center justify-content-center number-answer pointer rounded-circle">
                      <span class="number-answer-character pointer" :class="{'big-answer': detail.questions.length > 99}">{{ index + 1 }}</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="quiz-status">Hoàn thành: <span>{{answered}}/{{detail.number_question}}</span></div>
          </div>
        </div>
      </div>
      <div class="quiz-control d-flex align-items-center justify-content-center" v-if="isMobile">
        <span class="pointer" @click="setData(indexShow - 1)" :class="{'disable': indexShow <= 0}" v-html="require('~/assets/mlearn/icons/quiz/guide/left.svg?raw')"></span>
        <button class="view-all" @click="openFilter">Xem nhanh</button>
        <span class="pointer" @click="setData(indexShow + 1)" :class="{'disable': indexShow >= detail.questions.length - 1}" v-html="require('~/assets/mlearn/icons/quiz/guide/right.svg?raw')"></span>
      </div>
    </div>
    <div class="notify-pause-start" :style="{opacity: showNotifyPauseStart ? 1 : 0}">
      Chọn ‘’Tiếp tục” để tiếp tục làm bài
    </div>
    <b-modal id="modal-confirm-quiz" :modal-class="action" body-class="p-0" v-model="dialogVisiable" centered hide-header hide-footer :no-close-on-backdrop="action !== 'ended'" :no-close-on-esc="action !== 'ended'">
      <div class="content-modal position-relative">
        <img v-if="action === 'submit'" src="~/mlearn/icons/quiz/close.svg" alt="close" class="close position-absolute pointer" @click="dialogVisiable = false" />
        <div class="content-confirm text-break" :class="{'text-center': (action !== 'pause' && action !== 'continute')}" v-html="notifyText"></div>
        <div class="action-modal text-center">
          <button v-if="action === 'submit'" class="action-cancel" @click.prevent="dialogVisiable = false">Hủy</button>
          <button v-if="action === 'ended'" class="action-submit" @click.prevent="handerSubmit()">Nộp bài</button>
          <button v-if="action === 'submit'" class="action-submit" @click.prevent="handerSubmit()">Nộp bài</button>
          <button v-if="action === 'pause'" class="action-cancel" @click.prevent="handerPause()">Thoát</button>
          <button v-if="action === 'pause'" class="action-submit" @click.prevent="dialogVisiable = false; startTime()">Tiếp tục làm bài</button>
          <button v-if="action === 'continute'" class="action-cancel" @click.prevent="handerQuit()">Thoát</button>
          <button v-if="action === 'continute'" class="action-submit" @click.prevent="handerContinute()">Tiếp tục làm bài</button>
          <button v-if="action === 'quit'" class="action-submit" @click.prevent="dialogVisiable = false">Tiếp tục làm bài</button>
        </div>
      </div>
    </b-modal>
    <ModalReason v-if="objectId" :object-id="objectId" @reasonSuccess="reasonSuccess"/>
    <MobileFilter title="Xem nhanh" :show-filter="showFilter" @applyFilter="applyFilter" @closeFilter="closeFilter" :action="false">
      <div class="d-flex mb-3">
        <div class="note active mr-5">Câu đã làm</div>
        <div class="note">Câu chưa làm</div>
      </div>
      <div class="list-question-view">
        <div class="custom-row">
          <div class="custom-col-2 quiz-question-answer text-center" v-for="(item,index) in detail.questions" :key="item.id" :class="{'primary' : item.is_answered}">
            <span class="d-flex align-items-center justify-content-center number-answer pointer rounded-circle">
              <span class="number-answer-character pointer" :class="{'big-answer': detail.questions.length > 99}">{{ index + 1 }}</span>
            </span>
          </div>
        </div>
      </div>
      <template #action>
        <button class="btn-pause" @click="checkBeforePause">Tạm dừng</button>
        <button class="btn-submit ml-auto" @click="checkBeforeSubmit">Nộp bài</button>
      </template>
    </MobileFilter>
  </div>
</template>

<script>
import cookieParser from 'cookieparser'
import Question from '~/components/mlearn/quiz/question.vue'
import MobileFilter from '~/components/mlearn/mobile/filter.vue'
import ModalReason from '~/components/mlearn/quiz/reason.vue'
import Resource from '~/common/api/resource'
const examStartResource = new Resource('library/exam/start')
const examPauseResource = new Resource('library/exam/pause')
const examContinueResource = new Resource('library/exam/continue')
const examSubmitResource = new Resource('library/exam/submit')
export default {
  components: {
    Question,
    MobileFilter,
    ModalReason
  },
  layout ({ store }) {
    if (store.state.isMobile) {
      return 'mlearn'
    }
    return 'quiz'
  },
  middleware: ['authenticated'],
  scrollToTop: true,
  async asyncData ({ isDev, route, store, env, params, query, req, res, redirect, error }) {
    let token = ''
    if (req && req.headers && req.headers.cookie) {
      const requestCookies = cookieParser.parse(req.headers.cookie)
      token = requestCookies.access_token || ''
    }
    const tmp1 = await examStartResource.list({ exam_slug: params.slug }, token)
      .catch(() => {
        return error({ statusCode: 404, title: 'Bộ đề không tồn tại', message: 'Bộ đề đã bị xóa hoặc bị báo cáo vi phạm.' })
      })
    if (!tmp1) {
      return error({ statusCode: 404, title: 'Bộ đề không tồn tại', message: 'Bộ đề đã bị xóa hoặc bị báo cáo vi phạm.' })
    }
    if (!tmp1.data) {
      return error({ statusCode: 404, title: 'Bộ đề không tồn tại', message: 'Bộ đề đã bị xóa hoặc bị báo cáo vi phạm.' })
    }
    const detail = tmp1.data
    const action = detail.state === 2 ? 'continute' : 'pause'
    const isMobile = store.state.isMobile
    const indexShow = 0
    let dataShow = [...detail.questions]
    if (isMobile) {
      dataShow = dataShow.splice(indexShow, 1)
    }
    return {
      indexShow,
      detail,
      dataShow,
      timeLeft: (detail.time * 60 - detail.time_do),
      quizSticky: false,
      listSticky: false,
      processing: false,
      myInterval: null,
      myTimeOut: null,
      started: true,
      dialogVisiable: false,
      notifyText: '',
      action,
      objectId: '',
      showNotifyPauseStart: false,
      breakPoint: 0,
      breakPointFooter: 0,
      isMobile,
      showFilter: false
    }
  },
  computed: {
    answered () {
      const answers = this.detail.questions.filter(value => value.is_answered !== 0)
      return answers.length
    }
  },
  beforeMount () {
    window.addEventListener('beforeunload', this.handerQuit)
    window.addEventListener('scroll', this.handerScroll)
  },
  mounted () {
    if (this.action === 'continute') {
      this.dialogVisiable = true
      const questions = this.detail.questions
      const Answer = []
      questions.forEach((item, index) => {
        if (item.is_answered) {
          Answer.push(item)
        }
      })
      const arrTime = this.$helper.convertTimeToDayArr(this.timeLeft)
      this.notifyText = `Bộ đề: <b>${this.detail.name}</b>.<br>Thời gian còn lại: <b>${arrTime[2]} phút ${arrTime[3]} giây</b>.<br>Số câu đã làm: ${Answer.length}/${questions.length}`
    }
    window.addEventListener('beforeunload', this.handerQuit)
    window.addEventListener('scroll', this.handerScroll)
    this.handerScroll()
    if (this.detail.state === 2) {
      this.showNotifyPauseStart = true
      this.started = false
      setTimeout(() => {
        this.showNotifyPauseStart = false
      }, 3000)
    } else {
      this.started = true
      this.startTime()
    }
  },
  beforeDestroy () {
    window.removeEventListener('beforeunload', this.handerQuit)
    window.removeEventListener('scroll', this.handerScroll)
    if (this.myTimeOut) {
      clearTimeout(this.myTimeOut)
    }
  },
  methods: {
    handerScroll () {
      if (!this.breakPoint) {
        this.breakPoint = document.querySelector('.quiz-list-question').offsetTop
        this.breakPointFooter = document.querySelector('footer').offsetTop
      }
      const top = window.scrollY
      if (top > this.breakPoint) {
        this.quizSticky = true
        if (top <= (this.breakPointFooter - 550)) {
          this.listSticky = true
        } else {
          this.listSticky = false
        }
      } else {
        this.quizSticky = false
        this.listSticky = false
      }
    },
    scrollToQuestion (index) {
      const elmnt = document.getElementById('question-number-' + index)
      elmnt.scrollIntoView({ block: 'center', behavior: 'smooth' })
    },
    startTime () {
      if (this.timeLeft <= 0) { return }
      this.myInterval = setInterval(() => {
        this.timeLeft--
        if (this.timeLeft <= 0) {
          clearInterval(this.myInterval)
          this.action = 'ended'
          this.dialogVisiable = true
          this.notifyText = 'Đã hết thời gian làm bài'
          this.myTimeOut = setTimeout(() => {
            this.handerSubmit()
          }, 10000)
        }
      }, 1000)
    },
    handerPause () {
      if (this.processing) { return }
      const data = {
        exam_id: this.detail.id
      }
      this.processing = true
      examPauseResource.store(data)
        .then((res) => {
          if (res.status === 200) {
            clearInterval(this.myInterval)
            this.myInterval = null
            this.started = false
            this.action = ''
            this.dialogVisiable = false
            this.$router.push('/bo-de')
            localStorage.setItem('quiz', this.detail.name)
          }
        })
        .catch((err) => {
          this.$message({
            type: 'error',
            message: err.response.data.message
          })
        })
        .finally(() => {
          this.processing = false
        })
    },
    handerContinute () {
      if (this.detail.state !== 2) {
        return
      }
      if (this.processing) { return }
      const data = {
        exam_id: this.detail.id
      }

      this.processing = true
      examContinueResource.store(data)
        .then((res) => {
          if (res.status === 200) {
            if (this.myInterval) { clearInterval(this.myInterval) }
            this.startTime()
            this.started = true
            this.dialogVisiable = false
            this.detail.state = 1
          }
        })
        .catch((err) => {
          this.$message({
            type: 'error',
            message: err.response.data.message
          })
        })
        .finally(() => {
          this.processing = false
        })
    },
    checkBeforePause () {
      this.action = 'pause'
      this.dialogVisiable = true
      const questions = this.detail.questions
      const Answer = []
      questions.forEach((item, index) => {
        if (item.is_answered) {
          Answer.push(item)
        }
      })
      if (this.myInterval) { clearInterval(this.myInterval) }
      const arrTime = this.$helper.convertTimeToDayArr(this.timeLeft)
      this.notifyText = `Bộ đề: <b>${this.detail.name}</b>.<br>Thời gian còn lại: <b>${arrTime[1] !== '00' ? arrTime[1] + ' giờ ' : ''}${arrTime[2]} phút ${arrTime[3]} giây</b>.<br>Số câu đã làm: ${Answer.length}/${questions.length}`
    },
    checkBeforeQuit () {
      this.action = 'quit'
      this.dialogVisiable = true
      this.notifyText = 'Bạn chưa hoàn thành bộ đề.<br>Chắc chắn muốn thoát ?'
    },
    checkBeforeSubmit () {
      this.action = 'submit'
      this.dialogVisiable = true
      const questions = this.detail.questions
      const noAnswer = []
      const noAnswerIndex = []
      questions.forEach((item, index) => {
        if (!item.is_answered) {
          noAnswer.push(item)
          noAnswerIndex.push(index + 1)
        }
      })
      if (noAnswer.length) {
        if (noAnswer.length >= 10) {
          this.notifyText = `Bạn vẫn còn ${noAnswer.length} câu chưa hoàn thành.<br>Chắc chắn muốn nộp bài ?`
        } else {
          this.notifyText = `Bạn vẫn còn câu ${noAnswerIndex.join(',')} chưa hoàn thành.<br>Chắc chắn muốn nộp bài ?`
        }
        return
      }
      if (this.timeLeft > 0) {
        const arrTime = this.$helper.convertTimeToDayArr(this.timeLeft)
        this.notifyText = `Thời gian còn lại: <b>${arrTime[1] !== '00' ? arrTime[1] + ' giờ ' : ''}${arrTime[2]} phút ${arrTime[3]} giây</b>.<br>Chắc chắn muốn nộp bài ?`
      }
    },
    handerSubmit () {
      if (this.processing) { return }
      const data = {
        exam_id: this.detail.id
      }
      this.processing = true
      examSubmitResource.store(data)
        .then((res) => {
          if (res.status === 200) {
            if (this.myInterval) { clearInterval(this.myInterval) }
            if (this.myTimeOut) { clearTimeout(this.myTimeOut) }
            this.closeFilter()
            this.$router.push('/bo-de/ket-qua/' + this.$route.params.slug)
          }
        })
        .catch((err) => {
          this.$message({
            type: 'error',
            message: err.response.data.message
          })
        })
        .finally(() => {
          this.processing = false
        })
    },
    handerQuit (e) {
      this.$router.push('/bo-de')
    },
    reasonQuestion (questionId) {
      this.objectId = questionId
    },
    reasonSuccess () {
      this.objectId = ''
    },
    setData (index) {
      if (index === 'all') {
        if (this.dataShow.length < this.detail.questions.length) {
          this.dataShow = { ...this.detail.questions }
          this.indexShow = this.dataShow.length - 1
          return
        } else {
          index = 0
        }
      }
      if (index < 0) {
        return
      }
      if (index >= this.detail.questions.length) {
        index = this.detail.questions.length - 1
        return
      }
      const dataShow = Object.values(Object.assign({}, this.detail.questions))
      this.indexShow = index
      this.dataShow = dataShow.splice(this.indexShow, 1)
    },
    getIndexShow (index) {
      if (!this.isMobile) {
        return index
      }
      if (this.dataShow.length === this.detail.questions.length) {
        return index
      }
      if (this.indexShow < this.detail.questions.length) {
        return this.indexShow
      }
      return index
    },
    openFilter () {
      this.showFilter = true
    },
    closeFilter () {
      this.showFilter = false
    },
    applyFilter () {
    }
  }
}
</script>

<style lang="scss" scoped>
  .ml-quiz-layout{
    position: relative;
    @media (max-width:576px) {
      background: var(--white);
    }
    @media (max-width:576px) {}
    .quiz-header{
      background: var(--white);
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
      padding:14px 0 17px;
      margin-bottom: 1px;
      .quiz-name{
        font-weight: 500;
        font-size: 18px;
      }
      .logo{
        max-height: 42px;
      }
    }
    .quiz-header-mobile{
      padding:16px;
      .quiz-name{
        font-size: 16px;
      }
      .quiz-number{
        font-size: 14px;
      }
      .quiz-time{
        font-size: 14px;
        color: var(--danger);
        font-weight: 500;
      }
    }
    .quiz-sub-header{
      padding:10px 0;
      background: var(--white);
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
      position: relative;
      .quiz-time{
        font-weight: 500;
        font-size: 20px;
        color: var(--danger);
        margin-right:32px;
        img{
          width: 20px;
          margin-right:10px;
        }
      }
      .quiz-pause{
        background: var(--white);
        border: 1px solid var(--primary) !important;
        font-size: 16px;
        color: var(--primary);
        padding:12px 34px;
        margin-right:32px;
        border-radius: 22.5px;
      }
      .quiz-continue{
        background: var(--primary);
        border: 0 !important;
        font-size: 16px;
        color: var(--white);
        padding:12px 34px;
        margin-right:32px;
        border-radius: 22.5px;
      }
      .quiz-submit{
        border: 0;
        background: var(--primary);
        border-radius: 22.5px;
        font-weight: bold;
        font-size: 16px;
        color: var(--white);
        padding:12px 34px;
      }
      &.quiz-sticky{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 999;
      }
    }
    .quiz-content{
      padding-top: 24px;
      padding-bottom: 150px;
      @media (max-width:576px) {
        border-top: 1px solid #EBEBEB;;
        padding-top: 16px;
        padding-bottom: 20px;
      }
      .quiz-list-question{
        background: var(--white);
        box-shadow: 0px 2px 4px rgba(174, 174, 174, 0.34);
        border-radius: 8px;
        @media (max-width:576px) {
          box-shadow: none;
        }
        .quiz-question-item{
          border-bottom: 1px solid #EBEBEB;
          &:last-child{
            border-bottom:0;
          }
        }
      }
      .quiz-list-short{
        .quiz-sticky{
          position: fixed;
          top: 80px;
        }
        .quiz-list-short-form{
          background: var(--white);
          box-shadow: 0px 2px 4px rgba(174, 174, 174, 0.34);
          border-radius: 8px;
          .title{
            background: var(--primary);
            box-shadow: 0px 2px 4px #D8DDE6;
            border-radius: 8px 8px 0px 0px;
            font-weight: bold;
            font-size: 20px;
            text-align: center;
            color: var(--white);
            padding:13px 0;
          }
          .quiz-list-question-short{
            min-height: 40vh;
            max-height: 60vh;
            overflow-x: hidden;
            overflow-y: auto;
            padding:14px 12px 24px 12px;
            border-radius: 0 0 8px 8px;
            .custom-row{
              display: flex;
              flex-wrap: wrap;
              > [class*="custom-col-"] {
                padding-right: 12px;
                padding-left: 12px;
                padding-top:10px;
              }
              .custom-col-2{
                flex: 0 0 20%;
                max-width: 20%;
              }
            }
            .quiz-question-answer{
              .number-answer{
                height:38px;
                width:38px;
                background: #F0F2F5;
                .number-answer-character{
                  font-size: 16px;
                  &.big-answer{
                    font-size: 14px;
                  }
                }
              }
              &.primary{
                .number-answer{
                  background: var(--primary);
                  .number-answer-character{
                    color: var(--white);
                  }
                }
              }
              &.success{
                .number-answer{
                  background: #73BF44;
                  .number-answer-character{
                    color: var(--white);
                  }
                }
              }
            }
          }
        }
        .quiz-status{
          padding-top:12px;
          font-size: 14px;
          span{
            font-weight: bold;
            font-size: 16px;
            color: #1B1B1B;
          }
        }
      }
      .quiz-control{
        padding-top:24px;
        border-top: 1px solid #EEEEEE;;
        button{
          background: var(--white);
          border: 1px solid var(--primary) !important;
          border-radius: 22px;
          margin:0 40px;
          padding:10px 35px;
          font-weight: 500;
          font-size: 14px;
          text-align: center;
          color: var(--primary);
        }
      }
    }
    .notify-pause-start{
      padding:15px 32px;
      background: var(--white);
      border: 1px solid #FFEBCC;
      box-sizing: border-box;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.07);
      border-radius: 4px;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: #FFA216;
      width:325px;
      max-width: 100%;
      position: fixed;
      transform: translateX(-50%);
      left: 50%;
      top: 160px;
      z-index: 1000;
      transition: opacity 1.5s;
    }
  }
</style>
<style lang="scss">
  .ml-quiz-layout{
    .quiz-control{
      span{
        &.disable{
          svg{
            path{
              fill: #333333;
            }
          }
        }
      }
    }
    .form-filter{
      .note{
        position: relative;
        padding-left: 20px;
        --color: #E2E2E2;
        &.active{
          --color: var(--primary);
        }
        &::before{
          content: '';
          position: absolute;
          width:14px;
          height:14px;
          background: var(--color);
          border-radius: 50%;
          top:50%;
          left: 0;
          transform: translateY(-50%);
        }
      }
      .list-question-view{
        max-height: 333px;
        .custom-row{
          display: flex;
          flex-wrap: wrap;
          > [class*="custom-col-"] {
            padding-right: 12px;
            padding-left: 12px;
            padding-top:16px;
          }
          .custom-col-2{
            flex: 0 0 20%;
            max-width: 20%;
            .number-answer{
              height:50px;
              width:50px;
              background: #E3E3E3;
              .number-answer-character{
                font-size: 16px;
                &.big-answer{
                  font-size: 14px;
                }
              }
            }
            &.primary{
              .number-answer{
                background: var(--primary);
                .number-answer-character{
                  color: var(--white);
                }
              }
            }
          }
        }
      }
      .action{
        padding-top: 1rem;
        button{
          padding:12px 0;
          min-width: 160px;
          text-align: center;
          font-weight: 500;
          border-radius: 22px;
          font-size: 14px;
          border: 1px solid var(--primary);
        }
        .btn-pause{
          background: #FFFFFF;
          color: var(--primary);
        }
        .btn-submit{
          background: var(--primary);
          color: #FFFFFF;
        }
      }
    }
  }
  #modal-confirm-quiz{
    &___BV_modal_outer_{
      z-index: 9999 !important;
    }
    &.pause, &.continute{
      -webkit-backdrop-filter: blur(2px);
      backdrop-filter: blur(2px);
    }
    .modal-dialog{
      max-width:436px;
      .content-modal{
        padding:40px;
        @media (max-width:576px) {
          padding:20px;
        }
        .close{
          top: 12px;
          right: 12px;
        }
        .content-confirm{
          font-size: 18px;
        }
        .action-modal{
          padding-top:20px;
          @media (max-width:576px) {
            padding-top:10px;
          }
          button{
            min-width: 158px;
            @media (max-width:576px) {
              min-width: 120px;
            }
          }
          .action-cancel{
            background: var(--white);
            border: 1px solid var(--primary) !important;
            font-size: 16px;
            color: var(--primary);
            text-align: center;
            border-radius: 22.5px;
            padding:13px 0;
            margin-right:20px;
            @media (max-width:576px) {
              padding:8px 10px;
              margin-right:10px;
            }
          }
          .action-submit{
            border:0;
            background: var(--primary);
            font-weight: bold;
            font-size: 16px;
            color: var(--white);
            text-align: center;
            border-radius: 22px;
            padding:13px 10px;
            @media (max-width:576px) {
              padding:8px 10px;
            }
          }
        }
      }
    }
  }
</style>
